import { CSSProperties, useContext, useEffect, useState } from 'react';
import { CloseIcon, HelpIcon, InfoIcon } from '../../../icons/icons';
import RangeSlider from '../RangeSlider/RangeSlider';
import styles from './PropertyFilters.module.css';
import { MapFiltersType, PageContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import MobileTabletRangeSlider from '../MobileTabletRangeSlider/MobileTabletRangeSlider';
import PropertyTypeModal from '../../Modal/PropertyTypeModal/PropertyTypeModal';
import { useGetPropertiesQuery, useLazyGetPropertyQuery } from '../../../services/properties';
import { Property } from '../../../types/property';
import useConvertValues from '../../../hooks/useConvertValues';

interface Props {
  locationName: string;
}

const PropertyFilters: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton, setMapFilters, getMapFilter, resetMapFilters, polygones, displayedPolygones } =
    useContext(PageContext);
  const { data: properties } = useGetPropertiesQuery('ledar');
  const [getProperty] = useLazyGetPropertyQuery();
  const [selectedPropertyTypeCard, setSelectedPropertyTypeCard] = useState<string | null>(null);
  const [modalSelectedPropertyTypeCard, setModalSelectedPropertyTypeCard] = useState<string | null>(null);
  const [typeHovered, setTypeHovered] = useState<1 | 2 | 3 | null>(null);
  const [showPropertyTypeModal, setShowPropertyTypeModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [sliderKey, setSliderKey] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [typesData, setTypesData] = useState<Property[]>([]);
  const [hoveredTypeData, setHoveredTypeData] = useState<Property | null>(null);
  const hoveredTypeConvertedArea = useConvertValues({ value: hoveredTypeData?.unit_size, option: 'sqm' });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 700);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      if (properties) {
        const propertyTypes = Array.from(new Set(properties.map((property) => property.property_type_id)));

        const fetchedProperties = await Promise.all(
          propertyTypes.map(async (type) => {
            const property = properties.find((property) => property.property_type_id === type);

            if (property) {
              const fetchedProperty = await getProperty({ project: 'ledar', id: property.id });
              return fetchedProperty.data;
            }
          })
        );

        setTypesData(fetchedProperties.filter((property): property is Property => Boolean(property)));
      }
    })();
  }, [properties, getProperty]);

  const handleFilterClick = (key: MapFiltersType, value: string | number | number[]) => {
    if (value === getMapFilter(key)) {
      setMapFilters({ key, value: 'all' });
    } else {
      setMapFilters({ key, value });
    }
  };

  const closeModal = () => {
    setShowPropertyTypeModal(false);
    setModalSelectedPropertyTypeCard(null);
  };

  const handleTypeHover = (type: string) => {
    if (typesData) {
      const typeData = typesData.find((property) => type.includes(property.property_type_id.toString()));

      if (typeData) {
        setHoveredTypeData(typeData);
      }
    }
    setSelectedPropertyTypeCard(type);
  };

  const getStatusButtonClass = (status: string) => {
    return getMapFilter('status') === status
      ? `${styles.propertStatusButton} ${styles.selected}`
      : styles.propertStatusButton;
  };

  const getTypeButtonClass = (type: number) => {
    return getMapFilter('unitType') === type ? `${styles.propertyType} ${styles.selected}` : styles.propertyType;
  };

  const getColorForIcon = (type: number, isHovered: boolean) => {
    if (getMapFilter('unitType') === type) {
      return '#d27bfa';
    }
    if (isHovered) {
      return '#fff';
    }
    return '#3f3e45';
  };

  const handleReset = () => {
    setSliderKey((prevKey) => prevKey + 1);
  };

  const handleClear = () => {
    resetMapFilters();
    handleReset();
  };

  const handleClose = () => {
    setActiveButton(null);
  };

  const wrapperStyle: CSSProperties & { [key: string]: any } = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
    right: currentLanguage === 'ar' ? '2%' : 'unset',
    left: currentLanguage === 'ar' ? 'unset' : '2%',
    '--direction': currentLanguage === 'ar' ? '100%' : '-100%',
  };

  const propertyTypeCardStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.container} style={containerStyle}>
        <div className={styles.header}>
          <div>{t('propertyFilters.filters')}</div>
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon color={isHovered ? '#000' : '#fff'} />
          </button>
        </div>
        <div className={styles.propertStatusContainer}>
          <button
            className={getStatusButtonClass('available')}
            onClick={() => handleFilterClick('status', 'available')}
          >
            {t('propertyFilters.available')}
          </button>
          <button className={getStatusButtonClass('booked')} onClick={() => handleFilterClick('status', 'booked')}>
            {t('propertyFilters.booked')}
          </button>
          <button className={getStatusButtonClass('sold')} onClick={() => handleFilterClick('status', 'sold')}>
            {t('propertyFilters.sold')}
          </button>
        </div>
        <div className={styles.propertyText}>
          {t('propertyFilters.address', {
            value: displayedPolygones.length,
            total: polygones.length,
          })}
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <div className={styles.label}>{t('propertyFilters.unitType')}</div>
            <button className={styles.clearButton} onClick={handleClear}>
              {t('propertyFilters.clearFilters')}
            </button>
          </div>
          <div className={styles.propertyTypeWrapper}>
            <button
              className={getTypeButtonClass(1)}
              onMouseEnter={() => {
                handleTypeHover(t('propertyFilters.typeA'));
                setTypeHovered(1);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setTypeHovered(null);
              }}
              onClick={() => handleFilterClick('unitType', 1)}
            >
              {t('propertyFilters.typeA')}
              <div className={styles.ButtonIcon}>
                <InfoIcon color={getColorForIcon(1, typeHovered === 1)} />
              </div>
            </button>
            <button
              className={getTypeButtonClass(2)}
              onMouseEnter={() => {
                handleTypeHover(t('propertyFilters.typeB'));
                setTypeHovered(2);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setTypeHovered(null);
              }}
              onClick={() => handleFilterClick('unitType', 2)}
            >
              {t('propertyFilters.typeB')}
              <div className={styles.ButtonIcon}>
                <InfoIcon color={getColorForIcon(2, typeHovered === 2)} />
              </div>
            </button>
            <button
              className={getTypeButtonClass(3)}
              onMouseEnter={() => {
                handleTypeHover(t('propertyFilters.typeC'));
                setTypeHovered(3);
              }}
              onMouseLeave={() => {
                setSelectedPropertyTypeCard(null);
                setTypeHovered(null);
              }}
              onClick={() => handleFilterClick('unitType', 3)}
            >
              {t('propertyFilters.typeC')}
              <div className={styles.ButtonIcon}>
                <InfoIcon color={getColorForIcon(3, typeHovered === 3)} />
              </div>
            </button>
          </div>
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.label}>{t('propertyFilters.priceRange')}</div>

          {isMobile ? (
            <MobileTabletRangeSlider
              key={sliderKey}
              availability={[0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
              label="Currency"
              options={['SAR', 'USD', 'EUR', 'GBP']}
              minValue={polygones.length > 0 ? Math.min(...polygones.map((poly) => poly.price)) : 0}
              maxValue={polygones.length > 0 ? Math.max(...polygones.map((poly) => poly.price)) : 0}
              update={handleFilterClick}
              filterKey={'price'}
              getMapFilter={getMapFilter}
            />
          ) : (
            <RangeSlider
              key={sliderKey}
              availability={[0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
              label="Currency"
              options={['SAR', 'USD', 'EUR', 'GBP']}
              minValue={polygones.length > 0 ? Math.min(...polygones.map((poly) => poly.price)) : 0}
              maxValue={polygones.length > 0 ? Math.max(...polygones.map((poly) => poly.price)) : 0}
              update={handleFilterClick}
              filterKey={'price'}
              getMapFilter={getMapFilter}
            />
          )}
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.label}>{t('propertyFilters.plotArea')}</div>

          {isMobile ? (
            <MobileTabletRangeSlider
              key={sliderKey}
              availability={[0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
              label="Size Unit"
              options={['sqm', 'sqft']}
              minValue={polygones.length > 0 ? Math.min(...polygones.map((poly) => poly.plotArea)) : 0}
              maxValue={polygones.length > 0 ? Math.max(...polygones.map((poly) => poly.plotArea)) : 0}
              update={handleFilterClick}
              filterKey={'price'}
              getMapFilter={getMapFilter}
            />
          ) : (
            <RangeSlider
              key={sliderKey}
              availability={[0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
              label="Size Unit"
              options={['sqm', 'sqft']}
              minValue={polygones.length > 0 ? Math.min(...polygones.map((poly) => poly.plotArea)) : 0}
              maxValue={polygones.length > 0 ? Math.max(...polygones.map((poly) => poly.plotArea)) : 0}
              update={handleFilterClick}
              filterKey={'plotArea'}
              getMapFilter={getMapFilter}
            />
          )}
        </div>
      </div>
      {showPropertyTypeModal ? (
        <PropertyTypeModal selectedPropertyTypeCard={modalSelectedPropertyTypeCard} onClose={closeModal} />
      ) : null}
      {selectedPropertyTypeCard && (
        <div className={`${styles.propertyTypeCard} ${styles.appearAnimation}`} style={propertyTypeCardStyle}>
          <div className={styles.propertyTypeCardHeader}>
            <HelpIcon color="#D27BFA" />
            <div className={styles.propertyTypeCardHeaderTitle}>{selectedPropertyTypeCard}</div>
          </div>
          <div className={styles.propertyTypeCardContent}>
            <div className={styles.contentRow}>
              <div>{t('propertyFilters.bedrooms')}</div>
              <div>{hoveredTypeData?.number_bedrooms}</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t('propertyFilters.bathrooms')}</div>
              <div>{hoveredTypeData?.number_bathrooms}</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t('propertyFilters.floor')}</div>
              <div>{hoveredTypeData?.property_type?.floors.length}</div>
            </div>
            <div className={styles.contentRow}>
              <div>{t('propertyFilters.totalArea')}</div>
              <div>
                <span>{hoveredTypeConvertedArea} SQM</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyFilters;
