import React from 'react';
import { CurrencyData } from './PageProvider';
import { Property } from '../types/property';

export enum Pages {
  HomePage = 'HomePage',
  GlobePage = 'GlobePage',
  CountryPage = 'CountryPage',
  CityPage = 'CityPage',
  CommunityPage = 'CommunityPage',
  ResidencyPage = 'ResidencyPage',
  HousePlanPage = 'HousePlanPage',
}

export type MapFiltersType = 'status' | 'unitType' | 'price' | 'plotArea';

interface PageContextProps {
  pageTransitionTiming: number;
  resetButtons: () => void;
  activeButton: string | null;
  setActiveButton: (buttonName: string | null) => void;
  displayedProperty: Property | null;
  setDisplayedProperty: React.Dispatch<React.SetStateAction<Property | null>>;
  isBurgerMenuOpen: boolean;
  toggleBurgerMenu: () => void;
  isUnitInformationsOpen: boolean;
  toggleUnitInformations: () => void;
  isVRMode: boolean;
  setIsVRMode: React.Dispatch<React.SetStateAction<boolean>>;
  toggleVRMode: () => void;
  selectedRoom: string;
  setSelectedRoom: (room: string) => void;
  selectedFilter: string | null;
  setSelectedFilter: (filter: string | null) => void;
  mapFilters: {
    key: MapFiltersType;
    value: string | number | string[] | number[];
  }[];
  setMapFilters: ({ key, value }: { key: string; value: string | number | string[] | number[] }) => void;
  getMapFilter: (key: MapFiltersType) => string | number | string[] | number[] | undefined;
  resetMapFilters: () => void;
  areCaptionsOn: boolean;
  toggleCaptions: (state: boolean) => void;
  navigateToBreadcrumb?: (pageName: string) => void;
  activeAmenitiesButtons: { [key: string]: boolean };
  setActiveAmenitiesButtons: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  activeFilterButtons: { [key: string]: boolean };
  setActiveFilterButtons: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  polygones: any[];
  setPolygones: React.Dispatch<React.SetStateAction<any[]>>;
  displayedPolygones: any[];
  setDisplayedPolygones: React.Dispatch<React.SetStateAction<any[]>>;
  mapZoom: number;
  setMapZoom: React.Dispatch<React.SetStateAction<number>>;
  mapCenter: { lat: number; lng: number };
  setMapCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>;
  currencies: CurrencyData;
  currentFloor: number;
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;
  cameraUrl: string;
  setCameraUrl: React.Dispatch<React.SetStateAction<string>>;
}

const defaultPageContextValue: PageContextProps = {
  pageTransitionTiming: 1000,
  resetButtons: () => {},
  activeButton: null,
  setActiveButton: () => {},
  displayedProperty: null,
  setDisplayedProperty: () => {},
  isBurgerMenuOpen: false,
  toggleBurgerMenu: () => {},
  isUnitInformationsOpen: false,
  toggleUnitInformations: () => {},
  isVRMode: false,
  toggleVRMode: () => {},
  setIsVRMode: () => {},
  selectedRoom: 'Kitchen',
  setSelectedRoom: () => {},
  selectedFilter: null,
  setSelectedFilter: () => {},
  mapFilters: [
    { key: 'status', value: 'available' },
    { key: 'unitType', value: 'all' },
    { key: 'price', value: [0, 6000] },
    { key: 'plotArea', value: [0, 100] },
  ],
  setMapFilters: () => {},
  getMapFilter: () => '',
  resetMapFilters: () => {},
  areCaptionsOn: true,
  toggleCaptions: () => {},
  activeAmenitiesButtons: {
    hospital: false,
    park: false,
    retail: false,
    pocketPark: false,
    mosque: false,
    school: false,
  },
  setActiveAmenitiesButtons: () => {},
  activeFilterButtons: {
    landmarks: false,
    residential: false,
    retail: false,
    health: false,
    business: false,
    religious: false,
    education: false,
  },
  setActiveFilterButtons: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  polygones: [],
  setPolygones: () => {},
  displayedPolygones: [],
  setDisplayedPolygones: () => {},
  mapZoom: 0,
  setMapZoom: () => {},
  mapCenter: { lat: 0, lng: 0 },
  setMapCenter: () => {},
  currencies: {},
  currentFloor: 0,
  setCurrentFloor: () => {},
  cameraUrl: 'Corona Camera01',
  setCameraUrl: () => {},
};

export const PageContext = React.createContext(defaultPageContextValue);
