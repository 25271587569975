import { useTranslation } from 'react-i18next';
import styles from './MobileTabletVideoPage.module.css';
import { isMobile } from 'react-device-detect';
import { CSSProperties, useState } from 'react';
import { PlayerIcon } from '../../../icons/icons';
import VideoModal from '../../../components/Modal/VideoModal/VideoModal';
import { AnimatePresence } from 'framer-motion';

const MobileTabletVideoPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [showModal, setShowModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState<string>('');

  const handleVideoClick = (src: string) => {
    setVideoSrc(src);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (!isMobile) {
    return null;
  }

  const directionStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };
  return (
    <div className={styles.container}>
      <span className={styles.title}>{t(`videoPage.title`)}</span>
      <div className={styles.featuredVideosWrapper} style={directionStyle}>
        <div className={styles.innerFeaturedWrapper}>
          <div
            className={styles.featuredVideos}
            onClick={() => handleVideoClick('/assets/videos/sample1.mp4')}
            style={{
              backgroundImage: `url("/assets/images/videoThumbnail1.png")`,
            }}
          >
            <div className={styles.featuredVideoInfo}>
              <div className={styles.featuredVideoTitle}>{t(`videoPage.videoThumb`)}</div>
              <div className={styles.iconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
          </div>
          <div
            className={styles.featuredVideos}
            onClick={() => handleVideoClick('/assets/videos/sample2.mp4')}
            style={{
              backgroundImage: `url("/assets/images/videoThumbnail2.png")`,
            }}
          >
            <div className={styles.featuredVideoInfo}>
              <div className={styles.featuredVideoTitle}>{t(`videoPage.videoThumb`)}</div>
              <div className={styles.iconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.allVideosWrapper} style={directionStyle}>
        <div className={styles.sectionTitle}>{t(`videoPage.allVideos`)}</div>
        <div className={styles.videosWrapper}>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample1.mp4')}
                src="/assets/images/videoThumbnail1.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample2.mp4')}
                src="/assets/images/videoThumbnail2.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample1.mp4')}
                src="/assets/images/videoThumbnail1.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample2.mp4')}
                src="/assets/images/videoThumbnail2.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample1.mp4')}
                src="/assets/images/videoThumbnail1.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                onClick={() => handleVideoClick('/assets/videos/sample2.mp4')}
                src="/assets/images/videoThumbnail2.png"
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoInfo}>
              <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
              <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showModal && <VideoModal show={showModal} onClose={closeModal} initialVideoSrc={videoSrc} />}
      </AnimatePresence>
    </div>
  );
};

export default MobileTabletVideoPage;
