import { useContext, useEffect } from 'react'
import MapLocation from '../../components/Shared/MapLocation/MapLocation'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import { PageContext } from '../../contexts'
import styles from './CityPage.module.css'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import { Radius } from '../../icons/icons'
import LocationPin from '../../components/Shared/LocationPin/LocationPin'

export default function CityPage() {
    const { t } = useTranslation()
    const { activeButton } = useContext(PageContext)

    const compassPosition: Position = {
        mobile: {
            en: {
                right: '3%',
                top: '2%',
            },
            ar: {
                left: '3%',
                top: '2%',
            },
        },
        desktop: {
            en: {
                bottom: '5%',
                left: '2%',
            },
            ar: {
                bottom: '5%',
                right: '2%',
            },
        },
    }

    useEffect(() => {
        const setBodyHeight = () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
        }

        // Initial set
        setBodyHeight()

        // Update on resize
        window.addEventListener('resize', setBodyHeight)

        // Cleanup
        return () => {
            window.removeEventListener('resize', setBodyHeight)
        }
    }, [])

    return (
        <div className={styles.backgroundImage}>
            <div className={styles.container}>
                <LocationPin
                    type='Museum'
                    name={t(`locationPin.louvreMuseum`)}
                    position={{
                        mobile: { left: '25%', top: '40%' },
                        tablet: { left: '40%', top: '30%' },
                        desktop: { left: '40%', top: '30%' },
                    }}
                />
                <LocationPin
                    type='Museum'
                    name={t(`locationPin.guggenheimMuseum`)}
                    position={{
                        mobile: { left: '45%', top: '35%' },
                        tablet: { left: '40%', top: '30%' },
                        desktop: { left: '40%', top: '30%' },
                    }}
                />
                <LocationPin
                    type='Museum'
                    name={t(`locationPin.guggenheimMuseum`)}
                    position={{
                        mobile: { left: '35%', top: '60%' },
                        tablet: { left: '40%', top: '30%' },
                        desktop: { left: '40%', top: '30%' },
                    }}
                />
                <LocationPin
                    type='Airport'
                    name={t(`locationPin.airport`)}
                    position={{
                        mobile: { left: '60%', top: '70%' },
                        tablet: { left: '40%', top: '30%' },
                        desktop: { left: '40%', top: '30%' },
                    }}
                />
                {activeButton === t(`nav.radius`) ? (
                    <div className={styles.radius}>
                        <Radius />
                    </div>
                ) : null}
                <MapLocation
                    targetPage='/residency'
                    locationName={t(`locations.community`)}
                    position={{
                        mobile: { top: '35%', left: '80%' },
                        desktop: { top: '40%', left: '60%' },
                    }}
                />
                <NavigationTab locationName={t(`locations.city`)} />
                <HorizontalTaletMobileFilters />
                <Compass position={compassPosition} />
            </div>
        </div>
    )
}
