import React, { useEffect, useState } from 'react';
import styles from './RoomTab.module.css';
import { useTranslation } from 'react-i18next';
import { CameraType } from '../../../pages/HousePlanPage/HousePlanPage';

interface Props {
  onRoomSelect: (room: string) => void;
  cameras: CameraType[];
  iframe: HTMLIFrameElement | undefined;
  selectedButton: string;
  setSelectedButton: (url: string) => void;
}

const RoomTab: React.FC<Props> = ({ onRoomSelect, cameras, iframe, selectedButton, setSelectedButton }) => {
  const { t } = useTranslation();
  const rooms = [t('rooms.kitchen'), t('rooms.bathroom'), t('rooms.livingRoom'), t('rooms.office'), t('rooms.balcony')];

  const handleButtonClick = (url: string) => {
    iframe?.contentWindow?.postMessage({ v1: 'LayamaTeleportCamera', v2: url }, '*');
    setSelectedButton(url);
  };

  return (
    <div className={styles.container}>
      {cameras.map((camera, index) => (
        <button
          key={index}
          className={selectedButton === camera.url ? styles.selectedButton : styles.button}
          onClick={() => handleButtonClick(camera.url)}
        >
          {camera.alias}
        </button>
      ))}
    </div>
  );
};

export default RoomTab;
