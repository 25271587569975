interface AmenityPositions {
  [key: string]: number[];
}

interface AmenityCardProps {
  key: string;
  name: string;
  iconType: string;
  position: number[];
}

export const communityPageAmenityPositions: AmenityPositions = {
  // hospital: [-150, 10],
  park: [-150, 11],
  retail: [-150, 12],
  // pocketPark: [-100, 13],
  mosque: [-150, 14],
  school: [-150, 15],
};

export const residencyPageAmenityPositions: AmenityPositions = {
  // hospital: [-150, 10],
  park: [94, 47.5],
  retail: [150, 45],
  // pocketPark: [-3.8, 37],
  mosque: [-8.5, -48.5],
  school: [8.5, 50],
};

export function getAmenityCards(
  activeAmenitiesButtons: { [key: string]: boolean },
  amenityPositions: AmenityPositions
): AmenityCardProps[] {
  return Object.keys(activeAmenitiesButtons)
    .filter((key) => activeAmenitiesButtons[key])
    .map((key) => ({
      key,
      name: key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
      iconType: key,
      position: amenityPositions[key] || { top: 0, left: 0 },
    }));
}
