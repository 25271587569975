import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from '../../../contexts';
import { CloseIcon } from '../../../icons/icons';
import styles from './UnitInformations.module.css';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useConvertValues from '../../../hooks/useConvertValues';
import { useGetStatusQuery } from '../../../services/properties';

export default function UnitInformations() {
  const { resetButtons, setActiveButton, displayedProperty, setDisplayedProperty, toggleVRMode } =
    useContext(PageContext);
  const { data: statuses } = useGetStatusQuery({});
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [selectedAreaUnit, setSelectedAreaUnit] = useState('SQM');
  const [selectedCurrency, setSelectedCurrency] = useState('SAR');
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const convertedArea = useConvertValues({ value: displayedProperty?.unit_size, option: selectedAreaUnit });
  const convertedPrice = useConvertValues({ value: displayedProperty?.price, option: selectedCurrency });
  const [currentStatus, setCurrentStatus] = useState('available');

  useEffect(() => {
    if (displayedProperty) {
      const status = statuses?.find((status) => status.id === displayedProperty.property_status_id);
      setCurrentStatus(status?.name ?? 'available');
    }
  }, [displayedProperty, statuses]);

  const handleRegisterClick = () => {
    setActiveButton('register');
  };

  const handleClose = () => {
    setDisplayedProperty(null);
  };

  const handleFloorPlanClick = () => {
    navigate(`/house-plan?property=${displayedProperty?.id}`);
    resetButtons();
    // toggleVRMode();
  };

  const handleVRClick = () => {
    navigate(`/house-plan?property=${displayedProperty?.id}`);
    resetButtons();
    toggleVRMode();
  };

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === 'ar' ? 'unset' : '0%',
    left: currentLanguage === 'ar' ? '0%' : 'unset',
    '--direction': currentLanguage === 'ar' ? '-100%' : '100%',
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  const titleWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const descriptionStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const tableStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const priceWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const buttonWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const closeButtonStyle: CSSProperties = {
    right: currentLanguage === 'ar' ? 'unset' : '3%',
    left: currentLanguage === 'ar' ? '3%' : 'unset',
  };

  if (isMobile) {
    return null;
  }

  return (
    <div className={styles.container} style={containerStyle} ref={containerRef}>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles.closeButton}
        style={closeButtonStyle}
        onClick={handleClose}
      >
        <CloseIcon color={isHovered ? '#000' : '#fff'} />
      </button>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={[styles.availability, styles[currentStatus ?? 'available']].join(' ')}>
          {currentStatus ?? t(`propertyInformations.available`)}
        </div>
        <div className={styles.title}>{t(`propertyInformations.unit`, { id: displayedProperty?.name })}</div>
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {t(`propertyInformations.description`)}
      </p>
      <ImageCarousel
        images={['/assets/images/property1.jpg', '/assets/images/property2.jpg', '/assets/images/property3.jpg']}
      />
      <div className={styles.table} style={tableStyle}>
        <div className={styles.row}>
          <span>{t(`propertyInformations.bedrooms`)}</span>
          <span>{displayedProperty?.number_bedrooms}</span>
        </div>
        <div className={styles.row}>
          <span>{t(`propertyInformations.bathrooms`)}</span>
          <span>{displayedProperty?.number_bathrooms}</span>
        </div>
        <div className={styles.row}>
          <span>{t(`propertyInformations.floor`)}</span>
          <span>3</span>
        </div>
        <div className={styles.row}>
          <span>{t(`propertyInformations.totalArea`)}</span>
          <div>
            <span>{convertedArea}</span>{' '}
            <select
              value={selectedAreaUnit}
              onChange={(e) => setSelectedAreaUnit(e.target.value)}
              className={styles.areaSelect}
            >
              <option value="SQM">SQM</option>
              <option value="SQFT">SQFT</option>
            </select>
          </div>
        </div>
      </div>
      <div className={styles.priceWrapper} style={priceWrapperStyle}>
        <div className={styles.price}>{t(`propertyInformations.price`)}</div>
        <div className={styles.priceAmountWrapper}>
          <span className={styles.priceAmount}>{convertedPrice}</span>
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className={styles.currencySelect}
          >
            <option value="SAR">SAR</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
      </div>

      <div className={styles.buttonWrapper} style={buttonWrapperStyle}>
        <button className={styles.register} onClick={handleFloorPlanClick}>
          {t(`button.unitFloorPlan`)}
        </button>
        <button className={styles.register} onClick={handleVRClick}>
          {t(`button.exploreVR`)}
        </button>
        <button className={styles.callback} onClick={handleRegisterClick}>
          {t(`button.register`)}
        </button>
      </div>
    </div>
  );
}
