import { useContext, useState } from 'react';

import styles from './MobileTabletHomePage.module.css';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import Layout from '../../../components/Layout/Layout';
import { PageContext } from '../../../contexts';
import {
  HomeLogoIcon,
  VideoIcon,
  HomeArrowRightIcon,
  GalleryIcon,
  DocumentsIcon,
  LedarLogoIconSmall,
} from '../../../icons/icons';
import DocumentPage from '../../DocumentPage/DocumentPage';
import GalleryPage from '../../GalleryPage/GalleryPage';
import VideoPage from '../../VideoPage/VideoPage';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const MobileTabletHomePage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState('home');
  const [showShadow, setShowShadow] = useState({
    video: false,
    gallery: false,
    documents: false,
    arrow_video: false,
    arrow_gallery: false,
    arrow_documents: false,
  });

  const pageContext = useContext(PageContext);

  const { resetButtons, setActiveButton } = pageContext;

  const handleClick = () => {
    navigate('/residency');
    resetButtons();
    setActiveButton(null);
  };

  const handleMouseEnter = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: true,
      [`arrow_${buttonName}`]: true,
    });
  };

  const handleMouseLeave = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: false,
      [`arrow_${buttonName}`]: false,
    });
  };

  const handlePageChange = (newPage: string) => {
    setActivePage(newPage);
  };

  const goBackToHome = () => {
    setActivePage('home');
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div className={styles.container}>
      <AnimatePresence mode="wait">
        {activePage === 'home' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={styles.homeContainer}
          >
            <div className={styles.homeLogo}>
              <LedarLogoIconSmall color="#fff" />
            </div>
            <div className={styles.textWrapper}>
              <div className={styles.topBorder} />
              <div className={styles.title}>{t(`homePage.title`)}</div>
              <div className={styles.subTitle}>{t(`homePage.subTitle`)}</div>
              <button className={styles.exploreButton} onClick={handleClick}>
                {t(`homePage.explore`)}
              </button>
            </div>
            <div className={styles.menuWrapper}>
              <button
                className={styles.menuButton}
                onMouseEnter={() => handleMouseEnter('video')}
                onMouseLeave={() => handleMouseLeave('video')}
                onClick={() => setActivePage('video')}
              >
                <div className={styles.menuItemWrapper}>
                  <VideoIcon showShadow={showShadow.video} />
                  <div className={styles.menuItem}>{t(`homePage.videos`)}</div>
                </div>
                <HomeArrowRightIcon showShadow={showShadow.arrow_video} />
              </button>
              <button
                className={styles.menuButton}
                onMouseEnter={() => handleMouseEnter('gallery')}
                onMouseLeave={() => handleMouseLeave('gallery')}
                onClick={() => setActivePage('gallery')}
              >
                <div className={styles.menuItemWrapper}>
                  <GalleryIcon showShadow={showShadow.gallery} />
                  <div className={styles.menuItem}>{t(`homePage.gallery`)}</div>
                </div>
                <HomeArrowRightIcon showShadow={showShadow.arrow_gallery} />
              </button>
              <button
                className={styles.menuButton}
                onMouseEnter={() => handleMouseEnter('documents')}
                onMouseLeave={() => handleMouseLeave('documents')}
                onClick={() => setActivePage('documents')}
              >
                <div className={styles.menuItemWrapper}>
                  <DocumentsIcon showShadow={showShadow.documents} />
                  <div className={styles.menuItem}>{t(`homePage.documents`)}</div>
                </div>
                <HomeArrowRightIcon showShadow={showShadow.arrow_documents} />
              </button>
            </div>
          </motion.div>
        )}
        {activePage === 'video' && (
          <Layout activeButton="video" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
            <VideoPage />
          </Layout>
        )}
        {activePage === 'gallery' && (
          <Layout activeButton="gallery" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
            <GalleryPage />
          </Layout>
        )}
        {activePage === 'documents' && (
          <Layout activeButton="documents" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
            <DocumentPage />
          </Layout>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileTabletHomePage;
