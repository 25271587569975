import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageContext } from '../../contexts';

export default function PageTransition() {
  const location = useLocation();
  const [isAnimating, setIsAnimating] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const { pageTransitionTiming } = useContext(PageContext);

  useEffect(() => {
    if (firstLoad) {
      setTimeout(() => setIsAnimating(false), pageTransitionTiming * 2);
      setFirstLoad(false);
    } else {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), pageTransitionTiming * 2);
    }
  }, [location, firstLoad, pageTransitionTiming]);

  const variants = {
    initial: { opacity: firstLoad ? 1 : 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const transition = {
    type: 'tween',
    duration: pageTransitionTiming / 1000,
  };

  return (
    <AnimatePresence>
      {isAnimating && (
        <motion.div
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={transition}
          style={{
            position: 'fixed',
            background: 'black',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        />
      )}
    </AnimatePresence>
  );
}
