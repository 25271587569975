import { ReactNode, useContext } from 'react';
import styles from './Layout.module.css';
import { BackIcon } from '../../icons/icons';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../contexts';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
  activeButton: string;
  onBackClick: () => void;
  children: ReactNode;
  handlePageChange: (newPage: string) => void;
}

const Layout: React.FC<LayoutProps> = ({ activeButton, children, onBackClick, handlePageChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageContext = useContext(PageContext);

  const { setActiveButton, isModalOpen } = pageContext;

  const handleClick = () => {
    navigate('/residency');
    setActiveButton(null);
  };

  const containerStyle = isModalOpen ? { height: '100vh', overflow: 'hidden' } : {};
  return (
    <div className={styles.container} style={containerStyle}>
      <header className={styles.header}>
        <button className={styles.backButton} onClick={onBackClick}>
          <BackIcon />
          <span>{t(`homePage.back`)}</span>
        </button>
        <div className={styles.activePageWrapper}>
          <button
            className={`${styles.activePage} ${activeButton === 'video' ? styles.active : ''}`}
            onClick={() => handlePageChange('video')}
          >
            {t(`homePage.videos`)}
          </button>
          <button
            className={`${styles.activePage} ${activeButton === 'gallery' ? styles.active : ''}`}
            onClick={() => handlePageChange('gallery')}
          >
            {t(`homePage.gallery`)}
          </button>
          <button
            className={`${styles.activePage} ${activeButton === 'documents' ? styles.active : ''}`}
            onClick={() => handlePageChange('documents')}
          >
            {t(`homePage.documents`)}
          </button>
          <button className={styles.activePage} onClick={handleClick}>
            {t(`homePage.explore`)}
          </button>
        </div>
      </header>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeButton}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={styles.main}
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <button className={styles.exploreButton} onClick={handleClick}>
        {t(`homePage.explore`)}
      </button>
      <a
        href="http://www.virtualvisions.studio/"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.copyright}
      >
        {t(`copyright`)}
      </a>
    </div>
  );
};

export default Layout;
