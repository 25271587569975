import { useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from '../../contexts';
import styles from './HousePlanPage.module.css';
import PropertyInformationsButton from '../../components/Shared/PropertyInformationsButton/PropertyInformationsButton';
import PropertyInformations from '../../components/Shared/PropertyInformations/PropertyInformations';
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab';
import FloorTab from '../../components/Shared/FloorTab/FloorTab';
import RoomTab from '../../components/Shared/RoomTab/RoomTab';
import { isMobile as isDeviceMobile } from 'react-device-detect';
import HorizontalTabletHousePlanOptions from '../../components/Shared/HorizontalTabletHousePlanOptions/HorizontalTabletHousePlanOptions';
import { useTranslation } from 'react-i18next';
import MobileTabletPropertyInformations from '../../components/Shared/MobileTabletPropertyInformations/MobileTabletPropertyInformations';
import Captions from '../../components/Shared/Captions/Captions';
import Compass, { Position } from '../../components/Shared/Compass/Compass';
import { AnimatePresence, motion } from 'framer-motion';
import { MiniMap, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export type CameraType = {
  alias: string;
  url: string;
};

export type CameraFloorType = {
  type: number;
  floors: {
    number: number;
    cameras: CameraType[];
  }[];
};

const floorCameras: CameraFloorType[] = [
  {
    type: 1,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera006' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera011' },
          { alias: 'Dining Room', url: 'Corona Camera005' },
          { alias: 'Majless', url: 'Corona Camera004' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera024' },
          { alias: 'Junior Room 1', url: 'Corona Camera019' },
          { alias: 'Junior Room 2', url: 'Corona Camera021' },
          { alias: 'Junior Room 3', url: 'Corona Camera028' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera034' },
          { alias: 'Bath Room', url: 'Corona Camera035' },
        ],
      },
    ],
  },
];

const HousePlanPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { isVRMode, setSelectedRoom, activeButton, displayedProperty, currentFloor, cameraUrl } =
    useContext(PageContext);
  const [isTabletLandscape, setIsTabletLandscape] = useState(false);
  const [floorPlans, setFloorPlans] = useState<any[]>([]);
  const [model, setModel] = useState<number | null>(null);
  const [cameraButtons, setCameraButtons] = useState<any[]>([]);
  const [selectedButton, setSelectedButton] = useState('');

  const iframeRef = useRef<HTMLIFrameElement>();

  useEffect(() => {
    if (displayedProperty) {
      const model = displayedProperty.property_type_id;

      setModel(model);

      const imageNames = ['G_F', 'F_F', 'S_F'];
      const images: string[] = [];

      imageNames.forEach((imageName) => {
        const imagePath = `/assets/floorPlans/${model}/${imageName}.jpg`;

        images.push(imagePath);
      });

      setFloorPlans(images);
    }
  }, [displayedProperty]);

  useEffect(() => {
    if (model !== null && currentFloor !== null) {
      const cameras = floorCameras
        .find((camera) => camera.type === model)
        ?.floors.find((floor) => floor.number === currentFloor)?.cameras;

      if (cameras) {
        setCameraButtons(cameras);
      }
    }
  }, [model, currentFloor]);

  useEffect(() => {
    if (iframeRef.current?.contentWindow && currentFloor !== null && cameraButtons.length > 0) {
      iframeRef.current.contentWindow.postMessage({ v1: 'LayamaTeleportCamera', v2: cameraButtons[0].url }, '*');
    }
  }, [cameraButtons, currentFloor]);

  useEffect(() => {
    if (iframeRef) {
      setTimeout(() => {
        //iframe listen to messages
      }, 5000);
    }

    return () => {
      // window.removeEventListener('resize', handleResize)
    };
  }, [iframeRef, iframeRef.current]);

  useEffect(() => {
    //@ts-ignore
    const getCurrentPositionFromVR = (e) => {
      if (e.data && e.data.key === 'currentCamera') {
        let currentCamera = e.data.value;
        setSelectedButton(currentCamera);
        // console.log('You are on the camera: ', currentCamera);
      }
    };

    window.addEventListener('message', getCurrentPositionFromVR);

    return () => {
      window.removeEventListener('message', getCurrentPositionFromVR);
    };
  }, []);

  useEffect(() => {}, [currentFloor]);

  const compassPosition: Position = {
    mobile: {
      en: {
        right: '3%',
        top: '9%',
      },
      ar: {
        left: '3%',
        top: '9%',
      },
    },
    desktop: {
      en: {
        bottom: '5%',
        left: '2%',
      },
      ar: {
        bottom: '5%',
        right: '2%',
      },
    },
  };

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '-100%' : '100%',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '-100%' : '100%',
    },
  };

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener('resize', setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener('resize', setBodyHeight);
    };
  }, []);

  useEffect(() => {
    const checkOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > height && isDeviceMobile) {
        setIsTabletLandscape(true);
      } else {
        setIsTabletLandscape(false);
      }
    };

    window.addEventListener('resize', checkOrientation);

    checkOrientation();

    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  const handleRoomSelect = (room: string) => {
    setSelectedRoom(room);
  };

  if (!floorPlans.length) {
    return null;
  }

  return (
    <div className={styles.reset}>
      {isVRMode ? (
        <div className={styles.noBackground}>
          {/* @ts-ignore */}
          <iframe ref={iframeRef} src={cameraUrl + '&a=ab'} className={styles.backgroundIframe} title="VR Room" />
        </div>
      ) : (
        <TransformWrapper initialScale={0.3} minScale={0.3} smooth centerOnInit>
          {(utils) => (
            <div style={{ backgroundColor: 'rgb(22, 20, 36)' }}>
              <div
                style={{
                  position: 'fixed',
                  zIndex: 5,
                  top: '50px',
                  right: '50px',
                }}
              >
                <MiniMap width={200}>
                  <img src={floorPlans[currentFloor]} alt="" />
                </MiniMap>
              </div>

              <NavigationTab locationName={t(`locations.unit`)} />
              <Compass position={compassPosition} />
              <TransformComponent
                wrapperStyle={{
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                }}
              >
                {!isVRMode && model ? <Captions floor={currentFloor} plan={floorPlans[currentFloor]} /> : null}
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      )}
      <div className={styles.container}>
        {isVRMode ? (
          <RoomTab
            onRoomSelect={handleRoomSelect}
            cameras={cameraButtons}
            iframe={iframeRef.current}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
          />
        ) : null}
        <FloorTab />
        <AnimatePresence>
          {activeButton === 'PropertyInformations' && (
            <motion.div
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              className={styles.animatedContainer}
              style={{
                right: currentLanguage !== 'ar' ? 0 : 'unset',
                left: currentLanguage === 'ar' ? 0 : 'unset',
              }}
            >
              <PropertyInformations />
            </motion.div>
          )}
        </AnimatePresence>
        {activeButton === 'PropertyInformations' && <MobileTabletPropertyInformations />}
        <PropertyInformationsButton />
        {isDeviceMobile && isTabletLandscape ? <HorizontalTabletHousePlanOptions /> : null}
      </div>
    </div>
  );
};

export default HousePlanPage;
