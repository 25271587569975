import { PageContext } from '../../../contexts';
import { useContext } from 'react';
import styles from './MapLocation.module.css';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

interface Props {
  locationName: string;
  targetPage: string;
  position: {
    mobile: { top: string; left: string };
    desktop: { top: string; left: string };
  };
}

const MapLocation: React.FC<Props> = (props) => {
  const positionStyle = isMobile ? props.position.mobile : props.position.desktop;
  const pageContext = useContext(PageContext);
  const navigate = useNavigate();

  const { setActiveButton } = pageContext;

  const handleClick = () => {
    navigate(props.targetPage);
    setActiveButton(null);
  };

  return (
    <div className={styles.wrapper} style={{ top: positionStyle.top, left: positionStyle.left }}>
      <button className={styles.locationWrapepr} onClick={handleClick}>
        <span className={styles.locationName}>{props.locationName}</span>
      </button>
      <div className={styles.pinArea}>
        <div className={styles.pin} />
      </div>
    </div>
  );
};

export default MapLocation;
