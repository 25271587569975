import React, { useState, useEffect } from 'react';
import { MapFiltersType, PageContext } from './PageContext';
import { useTranslation } from 'react-i18next';
import { useLazyGetPropertyQuery } from '../services/properties';
import { useLocation } from 'react-router-dom';
import { Property } from '../types/property';

interface PageProviderProps {
  children: React.ReactNode;
}

export type CurrencyData = {
  [key: string]: number;
};

const url = 'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/sar.json';
const fallbackUrl = 'https://currency-api.pages.dev/v1/currencies/sar.json';

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [getProperty] = useLazyGetPropertyQuery();
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [displayedProperty, setDisplayedProperty] = useState<Property | null>(null);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState('TV Area');
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [mapZoom, setMapZoom] = useState(0);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [mapFilters, setMapFilters] = useState<{ key: MapFiltersType; value: string | number | string[] | number[] }[]>(
    [
      { key: 'status', value: 'available' },
      { key: 'unitType', value: 'all' },
      { key: 'price', value: [0, 6000] },
      { key: 'plotArea', value: [0, 100] },
    ]
  );
  const [isUnitInformationsOpen, setIsUnitInformationsOpen] = useState(false);
  const [isVRMode, setIsVRMode] = useState(false);
  const [areCaptionsOn, setAreCaptionsOn] = useState(true);
  const [activeAmenitiesButtons, setActiveAmenitiesButtons] = useState<{
    [key: string]: boolean;
  }>({
    hospital: false,
    park: false,
    retail: false,
    pocketPark: false,
    mosque: false,
    school: false,
  });
  const [activeFilterButtons, setActiveFilterButtons] = useState<{
    [key: string]: boolean;
  }>({
    landmarks: false,
    residential: false,
    retail: false,
    health: false,
    business: false,
    religious: false,
    education: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [polygones, setPolygones] = useState<any[]>([]);
  const [displayedPolygones, setDisplayedPolygones] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<CurrencyData>({});
  const [currentFloor, setCurrentFloor] = useState<number>(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [cameraUrl, setCameraUrl] = useState('');

  const fetchProperty = async (id: string) => {
    const property = getProperty({ project: 'ledar', id: Number(id) }).then((res) => res.data);

    return property;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const propertyId = urlParams.get('property');
    const path = window.location.pathname;

    if (propertyId && isFirstLoad) {
      (async () => {
        const matchedProperty = await fetchProperty(propertyId);

        if (matchedProperty && path === '/residency') {
          setDisplayedProperty(matchedProperty);
          setActiveButton('unitInformation');
        } else if (matchedProperty && path === '/house-plan') {
          setDisplayedProperty(matchedProperty);
        }
        setIsFirstLoad(false);
      })();
    }
  }, [setIsFirstLoad, isFirstLoad, setDisplayedProperty, setActiveButton]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;

    if (displayedProperty && (path === '/residency' || path === '/house-plan')) {
      urlParams.set('property', displayedProperty.id.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);
    } else if (!isFirstLoad && !displayedProperty) {
      urlParams.delete('property');
      window.history.pushState({}, '', `${window.location.pathname}`);
    }
  }, [displayedProperty, isFirstLoad, setDisplayedProperty]);

  useEffect(() => {
    if (!isFirstLoad) {
      const path = window.location.pathname;
      const urlParams = new URLSearchParams(window.location.search);

      if (path === '/residency' && activeButton !== 'unitInformation') {
        urlParams.delete('property');
        window.history.pushState({}, '', `${window.location.pathname}`);
        setDisplayedProperty(null);
      }
    }
  }, [activeButton, isFirstLoad, setDisplayedProperty]);

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath !== '/residency') {
      setActiveButton(null);
    }

    if (currentPath !== '/house-plan' && isVRMode) {
      setIsVRMode(false);
    }
  }, [location, isVRMode]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const floorId = urlParams.get('floor');

    if (floorId !== null) {
      setCurrentFloor(Number(floorId));
    }
  }, [setCurrentFloor]);

  useEffect(() => {
    const path = window.location.pathname;

    if (displayedProperty && currentFloor !== null && path === '/house-plan') {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('floor', currentFloor.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`);

      setActiveButton('unitInformation');
    }
  }, [currentFloor, displayedProperty]);

  useEffect(() => {
    (async () => {
      try {
        await fetch(url)
          .then((response) => response.json())
          .then((data) => {
            setCurrencies(data.sar);
          });
      } catch (err) {
        await fetch(fallbackUrl)
          .then((response) => response.json())
          .then((data) => {
            setCurrencies(data.sar);
          });
      }
    })();
  }, []);

  useEffect(() => {
    if (polygones.length > 0) {
      setMapFilters([
        { key: 'status', value: 'available' },
        { key: 'unitType', value: 'all' },
        {
          key: 'price',
          value: [Math.min(...polygones.map((poly) => poly.price)), Math.max(...polygones.map((poly) => poly.price))],
        },
        {
          key: 'plotArea',
          value: [
            Math.min(...polygones.map((poly) => poly.plotArea)),
            Math.max(...polygones.map((poly) => poly.plotArea)),
          ],
        },
      ]);
    }
  }, [polygones, setMapFilters]);

  useEffect(() => {
    // Update the selectedRoom when the language changes
    if (i18n.language === 'ar') {
      setSelectedRoom(t('rooms.tvArea'));
    } else {
      setSelectedRoom('TV Area');
    }
  }, [i18n.language, t]);

  const toggleVRMode = () => {
    if (displayedProperty?.property_type?.floors[0].points[0].url) {
      setCameraUrl(displayedProperty?.property_type?.floors[0].points[0].url);
    }
    setIsVRMode(!isVRMode);
  };

  const resetButtons = () => {
    setActiveAmenitiesButtons({
      hospital: false,
      park: false,
      retail: false,
      pocketPark: false,
      mosque: false,
      school: false,
    });
    setActiveFilterButtons({
      landmarks: false,
      residential: false,
      retail: false,
      health: false,
      business: false,
      religious: false,
      education: false,
    });
    setIsVRMode(false);
  };

  const toggleBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const toggleUnitInformations = () => {
    setIsUnitInformationsOpen(!isUnitInformationsOpen);
  };

  const toggleCaptions = () => {
    setAreCaptionsOn(!areCaptionsOn);
  };

  const setMapFiltersWrapper = ({ key, value }: { key: string; value: string | number | string[] | number[] }) => {
    const newMapFilters = mapFilters.map((filter) => {
      if (filter.key === key) {
        return { key, value: value };
      } else {
        return filter;
      }
    });

    setMapFilters(newMapFilters);
  };

  const getMapFilter = (key: MapFiltersType) => {
    return mapFilters.find((filter) => filter.key === key)?.value;
  };

  const resetMapFilters = () => {
    if (polygones.length > 0) {
      setMapFilters([
        { key: 'status', value: 'all' },
        { key: 'unitType', value: 'all' },
        {
          key: 'price',
          value: [Math.min(...polygones.map((poly) => poly.price)), Math.max(...polygones.map((poly) => poly.price))],
        },
        {
          key: 'plotArea',
          value: [
            Math.min(...polygones.map((poly) => poly.plotArea)),
            Math.max(...polygones.map((poly) => poly.plotArea)),
          ],
        },
      ]);
    }
  };

  return (
    <PageContext.Provider
      value={{
        pageTransitionTiming: 1000,
        resetButtons,
        activeButton,
        setActiveButton,
        displayedProperty,
        setDisplayedProperty,
        isBurgerMenuOpen,
        toggleBurgerMenu,
        isVRMode,
        toggleVRMode,
        setIsVRMode,
        selectedRoom,
        setSelectedRoom,
        selectedFilter,
        setSelectedFilter,
        mapFilters,
        setMapFilters: setMapFiltersWrapper,
        getMapFilter,
        resetMapFilters,
        areCaptionsOn,
        toggleCaptions,
        isUnitInformationsOpen,
        toggleUnitInformations,
        activeAmenitiesButtons,
        setActiveAmenitiesButtons,
        activeFilterButtons,
        setActiveFilterButtons,
        isModalOpen,
        setIsModalOpen,
        polygones: polygones,
        setPolygones: setPolygones,
        displayedPolygones,
        setDisplayedPolygones,
        mapZoom,
        setMapZoom,
        mapCenter,
        setMapCenter,
        currencies,
        currentFloor,
        setCurrentFloor,
        cameraUrl,
        setCameraUrl,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
