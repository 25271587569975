import { useTranslation } from 'react-i18next';
import styles from './GalleryPage.module.css';
import GalleryModal from '../../components/Modal/GalleryModal/GalleryModal';
import { CSSProperties, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

const GalleryPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [showModal, setShowModal] = useState(false);

  const handleGalleryClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const wrapperStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
    left: currentLanguage === 'ar' ? 'unset' : '4%',
    right: currentLanguage === 'ar' ? '4%' : 'unset',
  };

  const directionStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };
  return (
    <div className={styles.container}>
      <span className={styles.title}>{t(`galleryPage.title`)}</span>
      <div className={styles.galleriesWrapper} style={directionStyle}>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery1.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery2.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery3.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery4.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery5.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
        <div className={styles.galleryWrapper} onClick={handleGalleryClick}>
          <img className={styles.galleryThumbnail} src="/assets/images/gallery6.png" alt="gallery thumbnail" />
          <div className={styles.imageInfo} style={wrapperStyle}>
            <div className={styles.galleryName}>{t(`galleryPage.collection1`)}</div>
            <div className={styles.galleryItems}>54 {t(`galleryPage.photos`)}</div>
          </div>
        </div>
      </div>
      <AnimatePresence>{showModal && <GalleryModal show={showModal} onClose={closeModal} />}</AnimatePresence>
    </div>
  );
};

export default GalleryPage;
