import GoogleMapReact from 'google-map-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from '../../../contexts';

import animatePolylines from '../../../utils/animatedPolyline';

import { Property } from '../../../types/property';
import { Status } from '../../../types/status';

import { residencyPageAmenityPositions, getAmenityCards } from '../../../utils/amenityCardPositions';
import { useLazyGetPropertyQuery } from '../../../services/properties';

const transitions = {};

//@ts-ignore
function clearTransition(poly) {
//@ts-ignore
    if (transitions[poly.id]) {
//@ts-ignore
    clearInterval(transitions[poly.id]);
//@ts-ignore
    delete transitions[poly.id];
  }
}

//@ts-ignore
function smoothTransition(poly, targetOpacity, interval = 10, step = 0.01) {
  clearTransition(poly); // Clear any existing transition on this poly
  
  const currentOpacity = poly.get('fillOpacity');
  const direction = targetOpacity > currentOpacity ? 1 : -1;

//@ts-ignore
  transitions[poly.id] = setInterval(() => {
    let newOpacity = poly.get('fillOpacity') + step * direction;
    if ((direction === 1 && newOpacity > targetOpacity) || (direction === -1 && newOpacity < targetOpacity)) {
      newOpacity = targetOpacity;
    }

    poly.setOptions({ fillOpacity: newOpacity, strokeOpacity: newOpacity });

    if (newOpacity === targetOpacity) {
//@ts-ignore
      clearInterval(transitions[poly.id]);
//@ts-ignore
      delete transitions[poly.id];
    }
  }, interval);
}


export default function ResidencyMap({ properties, statuses }: { properties: Property[]; statuses: Status[] }) {
  const {
    displayedProperty,
    setDisplayedProperty,
    setActiveButton,
    getMapFilter,
    mapFilters,
    setPolygones,
    polygones,
    setDisplayedPolygones,
    displayedPolygones,
    mapZoom,
    activeAmenitiesButtons,
    mapCenter,
    setMapCenter,
  } = useContext(PageContext);
  const [getProperty] = useLazyGetPropertyQuery();
  const propertiesRef = useRef<Property[]>(properties);
  const statusesRef = useRef<Status[]>(statuses);
  const newPolygones = useRef<any[]>([]);
  const mapRef = useRef<any>(null);
  const mapsRef = useRef<any>(null);
  const currentInfoWindow = useRef<any>(null);
  const amenityCards = getAmenityCards(activeAmenitiesButtons, residencyPageAmenityPositions);
  const amenitiesRef = useRef<any[]>([]);
  const displayedPropertyRef = useRef<Property | null>(null);

  useEffect(() => {
    displayedPropertyRef.current = displayedProperty;
  }, [displayedProperty]);

  const updatePolygones = () => {
    setPolygones(newPolygones.current);
  };

  let animatedPolyline: {
    path: any[];
    geodesic: boolean;
    strokeColor: string;
    strokeOpacity: number;
    strokeWeight: number;
    editable: boolean;
    map: any;
    animationSpeed: number;
  }[] = [
    {
      path: [
        [-151, 32.5],
        [-151, 27.5],
        [-46, 27.5],
        [-46, 46.5],
      ],
      geodesic: true,
      strokeColor: '#0000FF',
      strokeOpacity: 1.0,
      strokeWeight: 10,
      editable: false,
      map: null,
      animationSpeed: 100000,
    },
  ];

  useEffect(() => {
    if (properties) {
      propertiesRef.current = properties;
    }
  }, [properties]);

  useEffect(() => {
    if (statuses) {
      statusesRef.current = statuses;
    }
  }, [statuses]);

  const [amenityPoints, setAmenityPoints] = useState<any[]>([]);

  useEffect(() => {
    amenitiesRef.current = amenityCards;

    if (!mapRef.current || !mapsRef.current) {
      return;
    }

    amenityPoints.forEach((point) => {
      const alreadyExistingPoint = amenityCards.find(
        (amenity) => JSON.stringify(amenity.position) === JSON.stringify(point.latLng)
      );

      if (!alreadyExistingPoint) {
        point.marker.setMap(null);
        amenityPoints.splice(amenityPoints.indexOf(point), 1);
      }
    });

    amenityCards.forEach((amenity: any) => {
      const existingAmenity = amenityPoints.find(
        (point) => JSON.stringify(point.latLng) === JSON.stringify(amenity.position)
      );

      if (!existingAmenity) {
        const point = new mapsRef.current.LatLng(amenity.position[1], amenity.position[0]);

        const renderIcon = (iconType: string) => {
          if (iconType.includes('hospital')) {
            return require('../../../icons/amenity/hospital.svg');
          } else if (iconType.includes('park')) {
            return require('../../../icons/amenity/park.svg');
          } else if (iconType.includes('retail')) {
            return require('../../../icons/amenity/retail.svg');
          } else if (iconType.includes('pocketPark')) {
            return require('../../../icons/amenity/pocket.svg');
          } else if (iconType.includes('mosque')) {
            return require('../../../icons/amenity/religious.svg');
          } else if (iconType.includes('school')) {
            return require('../../../icons/amenity/education.svg');
          } else {
            return null;
          }
        };

        const marker = new mapsRef.current.Marker({
          position: point,
          map: mapRef.current,
          icon: {
            url: renderIcon(amenity.iconType).default,
            scaledSize: new mapsRef.current.Size(28, 28),
          },
          animation: mapsRef.current.Animation.DROP,
        });

        marker.addListener('mouseover', (mouseEvent: any) => {
          openInfoWindow(mapRef.current, mapsRef.current, amenity, mouseEvent);
        });

        marker.addListener('click', (touchEvent: any) => {
          openInfoWindow(mapRef.current, mapsRef.current, amenity, touchEvent);
        });

        marker.addListener('mouseout', () => {
          handleCloseInfoWindow();
        });

        mapRef.current.addListener('click', () => {
          handleCloseInfoWindow();
        });

        setAmenityPoints([...amenityPoints, { latLng: amenity.position, marker }]);
      }
    });
  }, [activeAmenitiesButtons, amenityCards, amenityPoints, mapRef, mapsRef, setAmenityPoints]);

  const openInfoWindow = (map: any, maps: any, amenity: any, mouseEvent: any) => {
    const { iconType, name } = amenity;
    let color: string = '';

    if (iconType.includes('hospital')) {
      color = '#ff6767';
    } else if (iconType.includes('park')) {
      color = '#95d935';
    } else if (iconType.includes('retail')) {
      color = '#D27BF9';
    } else if (iconType.includes('pocketPark')) {
      color = '#6780ff';
    } else if (iconType.includes('mosque')) {
      color = '#F8D648';
    } else if (iconType.includes('school')) {
      color = '#987BFA';
    }

    let infoWindow = new maps.InfoWindow({
      content: `
          <div id="info-window">
            <div class="place-name">
              <span style="color: ${color};">${name}</span>
            </div>
          
        </div>
      `,

      // <div class="place-info">
      //       <img src="/assets/images/mosque.jpg" alt="${name}" class="info-window-image" />
      //       <div class="info-window-content">
      //         <h3>${name}</h3>
      //         <div>Al Fawzen Mosque is a magnificent religious edifice nestled in the heart of Riyadh, the bustling capital city of Saudi Arabia. This architectural marvel stands as a testament to the rich Islamic heritage and cultural identity of the region.</div>
      //       </div>
      //     </div>
    });

    infoWindow.id = mouseEvent.id;

    infoWindow.setPosition(mouseEvent.latLng);

    infoWindow.setOptions({
      pixelOffset: new maps.Size(15, 0),
    });

    currentInfoWindow.current?.close();

    currentInfoWindow.current = infoWindow;
    infoWindow.open(map);

    // hoverInfoWindow(map, maps, amenity, mouseEvent);
  };

  const handleCloseInfoWindow = () => {
    if (currentInfoWindow.current !== null) {
      currentInfoWindow.current?.close();
    }
  };

  useEffect(() => {
    let tempDisplayedPolygones: any[] = [];

    polygones.length > 0 &&
      polygones.forEach((poly) => {
        let isVisible = true;

        mapFilters.forEach((filter) => {
          if (filter.value === 'all') return;

          if (filter.key === 'price' || filter.key === 'plotArea') {
            if (
              Array.isArray(filter.value) &&
              (poly[filter.key] < filter.value[0] || poly[filter.key] > filter.value[1])
            ) {
              isVisible = false;
            }
          } else {
            if (poly[filter.key] !== filter.value) {
              isVisible = false;
            }
          }
        });

        if (displayedProperty?.id === poly.id) {
            poly.setOptions({ fillOpacity: 0, strokeOpacity: 0 });
        } else {
            if (isVisible) {
                tempDisplayedPolygones.push(poly);
                poly.setOptions({ fillOpacity: 0.35, strokeOpacity: 0.8 });
                smoothTransition(poly, 0.35);
              } else {
                  smoothTransition(poly, 0);
              //   poly.setOptions({ fillOpacity: 0, strokeOpacity: 0 });
              }
        }

      });

    const oldIds = displayedPolygones.map((poly) => poly.id);
    const newIds = tempDisplayedPolygones.map((poly) => poly.id);

    if (JSON.stringify(newIds) !== JSON.stringify(oldIds)) {
      setDisplayedPolygones(tempDisplayedPolygones);
    }
  }, [getMapFilter, polygones, setDisplayedPolygones, displayedPolygones, mapFilters]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapZoom);
    }
  }, [mapZoom, mapRef]);

  const handleFetchProperty = async (id: number) => {
    const property = await getProperty({ project: 'ledar', id });

    if (property.data) {
      setDisplayedProperty(property.data);
    }
  };

  const handleUnitClick = (property: any) => {
    if (displayedPropertyRef.current?.id !== property.id) {
      setDisplayedProperty(property);
      setActiveButton('unitInformation');

      handleFetchProperty(property.id);


    } else {
      setDisplayedProperty(null);
      setActiveButton(null);
    }
  };

  if (!properties || properties.length === 0 || !statuses || statuses.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyB1vWnJNit84C9jqLEHuWMkVtUGwK6ZOlU' }}
        defaultCenter={{ lat: 0, lng: 0 }}
        center={mapCenter}
        defaultZoom={0}
        resetBoundsOnResize={true}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          // @ts-ignore
          restriction: {
            latLngBounds: {
              north: 70.60291256794906,
              south: -70.61164610863783,
              west: -179.997995985153,
              east: 179.91312216997449,
            },
            // strictBounds: false,
          },
        }}
        onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => {
          mapRef.current = map;
          mapsRef.current = maps;

          let layer = new maps.ImageMapType({
            name: 'vvsCustomLayer',
            getTileUrl: (coord: any, zoom: any) => {
              return `tilestest3/${zoom}/tile_${coord.x}_${coord.y}.jpg`;
            },
            tileSize: new maps.Size(256, 256),
            minZoom: 0,
            maxZoom: 7,
          });

          map.mapTypes.set('vvsCustomLayer', layer);
          map.setMapTypeId('vvsCustomLayer');
          //@ts-ignore
          map.addListener('click', (e) => {
            console.log(e.latLng.lat(), e.latLng.lng());
          })

          properties.length > 0 &&
            properties.forEach((property) => {
              let { id, geometry, property_status_id, property_type_id, price, unit_size, id_unit } = property;

              let poly = new maps.Polygon({
                paths: geometry.coordinates[0].map((el) => {
                  return {
                    //@ts-ignore
                    lat: el[1],
                    //@ts-ignore
                    lng: el[0],
                  };
                }),
                id: id,
                strokeColor: "#00FF00",
                strokeOpacity: 0.55,
                strokeWeight: 1,

                fillColor: "#00FF00",
                fillOpacity: 0.35,
                status: "available",
                unitType: property_type_id,
                price: price,
                plotArea: unit_size,
              });

              poly.setMap(map);

              mapRef.current = map;
              newPolygones.current.push(poly);

              poly.addListener('click', () => {
                handleUnitClick(property);
              });


                poly.addListener('mouseover', () => {
                    newPolygones.current.forEach((cpoly) => {
                        if (poly.id === cpoly.id || cpoly.id === displayedProperty?.id) {
                            smoothTransition(poly, 0);
                        } else {
                            smoothTransition(cpoly, 0.35);
                        }
                    })
            
                })
                // poly.addListener('mouseout', () => {
                //   smoothTransition(poly, 0.35);
                // })
            });

          updatePolygones();
        }}
      />
    </div>
  );
}
