import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import styles from './HousePlanOptions.module.css';
import { PageContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface Props {
  selectedOption: string | null;
  onSelectOption: (filter: string | null) => void;
}

const HousePlanOptions: React.FC<Props> = ({ selectedOption, onSelectOption }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { isVRMode, toggleVRMode, toggleCaptions } = useContext(PageContext);

  useEffect(() => {
    onSelectOption(t(`button.info`));
  }, [onSelectOption, t]);

  const [showFloorCard, setShowFloorCard] = useState(false);

  const handle3DButtonClick = () => {
    toggleVRMode();
    onSelectOption('3D');
  };

  const handleFloorButtonClick = () => {
    if (selectedOption === 'Floor') {
      onSelectOption(null);
    } else {
      setShowFloorCard(!showFloorCard);
      onSelectOption('Floor');
    }
  };

  // eslint-disable-next-line
  const handleFloorSelection = (floor: string) => {
    onSelectOption('3D');
  };

  const handleInfoSelection = () => {
    onSelectOption(t(`button.info`));
    toggleCaptions(true);
  };

  const containerStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.sliderContainer}>
        <ThreeDButton onClick={handle3DButtonClick} isActive={selectedOption === '3D'} isVRMode={isVRMode} />
        <div>
          <FloorButton onClick={handleFloorButtonClick} isActive={selectedOption === 'Floor'} />
          {selectedOption === 'Floor' ? <FloorCard /> : null}
        </div>
        {isVRMode ? <AdditionalButtons /> : null}

        <InfoButton onClick={handleInfoSelection} isActive={selectedOption === t(`button.info`)} />
      </div>
    </div>
  );
};

const ThreeDButton: React.FC<{
  onClick: () => void;
  isActive: boolean;
  isVRMode: boolean;
}> = ({ onClick, isActive, isVRMode }) => (
  <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
    {isVRMode ? 'Plan' : '3D'}
  </button>
);

const FloorButton: React.FC<{ onClick: () => void; isActive: boolean }> = ({ onClick, isActive }) => (
  <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
    GF
  </button>
);

const FloorCard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const direction = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  const [selectedFloor, setSelectedFloor] = useState<string | null>(t('button.groundFloor'));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 576;
  const positionValue = isMobile ? '15%' : '9%';

  const cardStyle: CSSProperties = {
    direction,
    right: currentLanguage === 'ar' ? positionValue : 'unset',
    left: currentLanguage === 'ar' ? 'unset' : positionValue,
  };

  return (
    <div className={styles.card} style={cardStyle}>
      {[t('button.roofFloor'), t('button.firstFloor'), t('button.groundFloor')].map((floor) => (
        <div
          key={floor}
          onClick={() => {
            setSelectedFloor(floor);
          }}
          className={`${styles.floor} ${selectedFloor === floor ? styles.selectedFloor : ''}`}
        >
          {floor}
        </div>
      ))}
    </div>
  );
};

const AdditionalButtons: React.FC = () => {
  const { t } = useTranslation();
  const { selectedRoom, setSelectedRoom } = useContext(PageContext);

  const handleRoomClick = (room: string) => {
    setSelectedRoom(room);
  };

  return (
    <>
      {[t('rooms.kitchen'), t('rooms.bathroom'), t('rooms.livingRoom'), t('rooms.office'), t('rooms.balcony')].map(
        (label) => (
          <button
            key={label}
            className={`${styles.roomButton} ${selectedRoom === label ? styles.roomButtonSelected : ''}`}
            onClick={() => handleRoomClick(label)}
          >
            {label}
          </button>
        )
      )}
    </>
  );
};

const InfoButton: React.FC<{ onClick: () => void; isActive: boolean }> = ({ onClick, isActive }) => {
  const { t } = useTranslation();
  return (
    <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
      {t(`button.info`)}
    </button>
  );
};

export default HousePlanOptions;
